<!-- src/routes/account/+page.svelte -->
<script lang="ts">
	import type { Category } from '$lib/types';
	import TopCategoryCard from '$lib/components/category/TopCategoryCard.svelte';

	export let categories: Category[];

	const icons: Record<string, string> = {
		'arts-music': '/icons/Arts.png',
		wellbeing: '/icons/Wellbeing.png',
		connect: '/icons/Connect.png',
		produce: '/icons/Produce.png',
		events: '/icons/Events.png',
		accomodation: '/icons/accomodation.png',
		services: '/icons/Services.png',
		'land-living': '/icons/Landshare.png',
		shop: '/icons/Eat & Drink.png',
		spirtual: '/icons/Movement.png',
		markets: '/icons/WellButtonsTrades.png'
	};
</script>

<div class="categories container mx-auto p-4">
	<div class="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
		<TopCategoryCard
			category={{ name: 'All Listings', slug: 'all' }}
			icon="/icons/All Listings.png"
			href="/listings"
		/>
		{#each categories as category}
			<TopCategoryCard
				{category}
				icon={icons[category.slug] || null}
				href="/categories/{category.slug}"
			/>
		{/each}
	</div>
</div>

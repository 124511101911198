<!-- src/routes/account/+page.svelte -->
<script lang="ts">
	import Heading from '$lib/components/core/Heading.svelte';
	import CategoryGrid from '$lib/components/category/CategoryGrid.svelte';

	export let data;

	let { categories } = data;
	$: ({ categories } = data);
</script>

<Heading title="Categories" />
<CategoryGrid {categories} />

<script lang="ts">
	export let category: { slug: string; name: string };
	export let icon: string | null = null;
	export let href: string;

	let isLoading = false;
</script>

{#if isLoading}
	<div class="card p-4 highlight-on-hover transition duration-150 ease-in-out">
		<section class="p-4">
			<div class="placeholder-circle w-20 mx-auto" />
		</section>
		<footer class="card-footer text-center loading-text">Loading...</footer>
	</div>
{:else}
	<a
		on:click={() => {
			isLoading = true;
		}}
		{href}
	>
		<div class="card p-4 highlight-on-hover transition duration-150 ease-in-out">
			<section class="p-4">
				{#if icon}
					<img src={icon} alt={category.name} class="w-20 mx-auto" />
				{:else}
					<div class="placeholder-circle w-20 mx-auto" />
				{/if}
			</section>
			<footer class="card-footer text-center">{category.name}</footer>
		</div>
	</a>
{/if}

<style>
	.highlight-on-hover:hover {
		background-color: #6366f1;
		color: #ffffff;
		transform: translateY(-2px);
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
	}
</style>
